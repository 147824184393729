<template>
  <div>
    <Login/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"></div>
          <div class="div-content-form">
            <p class="form-titre-page inscription-attestation-proprietaire">Connectez-vous à votre espace Pautions et signez le contrat d'assurance de votre locataire.<span class="text-span-9"></span></p>
            <div class="form-inscription-locataire w-form">
              <p class="txt-inscription-locataire">Vous êtes : {{user.prenom}} {{user.nom}}</p>
              <p class="txt-inscription-locataire">Mail : {{user.email}}</p>
              <p class="txt-inscription-locataire">Téléphone : {{user.telephone}}</p>
              <form name="wf-form-Mail" data-name="Mail" redirect="/form-souscription-06" data-redirect="/form-souscription-06" method="get" class="form-attestation-complete" aria-label="Mail">
                <p class="form-second-titre">Merci de créer un mot de passe pour votre compte Pautions :<span class="text-span-9"></span></p>
                <div class="div-champs-form pass">
                  <p class="texte-info-form motdepasse">Mot de passe</p>
                  <div class="div-block-11">
                    <input type="text" class="champs-formulaire pass w-input" maxlength="256" name="Mot-de-passe" data-name="Mot de passe" placeholder=""  v-model="user.password">
                    <img width="25" loading="lazy" :src="require('@/assets/images/ic_eye_open.svg')" alt="" class="mot-de-passe-visible"><img width="25" loading="lazy" :src="require('@/assets/images/ic_eye_open.svg')" alt="" class="mot-de-passe-non-visible">
                  </div>
                </div>
                <a v-if="loader === false" data-wait="Se connecter et signer" style="text-align:center;" class="bouton-continuer mail w-button" @click="save()">
                  signez
                </a>
                <div v-else style="text-align: center;">
                  <img :src="require('@/assets/images/spinner.svg')">
                  <p style="color:rgb(251, 177, 205);font-weight:bold;font-size:18px;">Merci de patienter pendant la génération de votre contrat.</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Login from '@/components/Menu/Login';
export default {
  name: "InscriptionProrprietaire",
  components: {
    Login
  },
  data(){
    return {
      ready:false,
      loader:false,
      user:{}
    }
  },
  beforeMount(){
    if(this.$route.query.uuid != undefined){
      this.$store.api.ajax('/users/proprietaire/'+this.$route.query.uuid, null, res => {
        if(res.status === true){
          this.user = res.data.user;
          this.user.password = null;
          this.ready = true;
        }
      })
    }
  },
  methods: {
    save(){
      var params = {
        uuid:this.$route.query.uuid,
        user:this.user
      }
      this.loader = true;
      this.$store.api.ajax('/users/proprietaire', params, res => {
        if(res.status === true){
          console.log(res);
          this.$store.api.saveToken(res.data.token);
          this.$store.api.saveUser(res.data);
          this.$store.api.ajax('/sign/proprietaire/link',{uuid:this.$route.query.uuid}, res => {
            if(res.status === true){
              location.href = res.data;
            }else{
              this.loader = false;
            }
          })
        }else{
          this.loader = false;
        }
      });
    }
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-form";
  },
}
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
